import { template as template_afc23d90bf2a4cf39dd8c14a3ac7b1cb } from "@ember/template-compiler";
const FKLabel = template_afc23d90bf2a4cf39dd8c14a3ac7b1cb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
