import { template as template_735fa2708c4141f982bac4f7cbaa6aa4 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_735fa2708c4141f982bac4f7cbaa6aa4(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
