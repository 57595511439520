import { template as template_1c179546a36b438bbaa5d460ecbd97c4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1c179546a36b438bbaa5d460ecbd97c4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
